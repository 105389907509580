import React from 'react';
import Layout from '../components/layout';

export default function BlogPost() {
	return (
		<Layout>
			<div>Hello Sound Pack Page</div>
		</Layout>
	);
}
